import React from "react"
import Header from "./global/Header"
import Footer from "./global/Footer"
export default function Layout(props) {
  return (
    <div>
      <Header path={props.path} />
      <main className="container clearfix">
        <div className="wrapper clearfix">
          {props.children}
        </div>
      </main>
      <Footer />
    </div>
  )
}