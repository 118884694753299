import React, {useState, useRef} from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Logo from '../../images/logo.jpg'
import TennisMenu from '../menu/TennisMenu'
import SquashMenu from '../menu/SquashMenu'
import RacketsMenu from '../menu/RacketsMenu'
import MainMenu from '../menu/MainMenu'

export default function Header({path}) {

  const [display, setDisplay] = useState(false)
  const mobnav = useRef()
  const navToggle = useRef()
  const logo = useRef()
  const header = useRef()

  const handleNavToggle = () => {
    
      if(display === false){
        
          mobnav.current.classList.add('display');
            navToggle.current.classList.add('active');
            logo.current.classList.add('active');
            header.current.classList.add('transparent');
            setDisplay(true)
            
            setTimeout(function(){   
              mobnav.current.classList.add('show');
              
              const lines = Array.from(mobnav.current.querySelectorAll('li'))

              lines.forEach((line, index) => {
                setTimeout(function(){
                  line.classList.add('show');
                }, 80*index);
              })

          },10);
      }else{

          const lines = Array.from(mobnav.current.querySelectorAll('li'))

          lines.forEach((line, index) => {
            setTimeout(function(){
              line.classList.remove('show');
            }, 80*index);
          })
            
            setTimeout(function(){
              navToggle.current.classList.remove('active');
              mobnav.current.classList.remove('show');
              logo.current.classList.remove('active');
              header.current.classList.remove('transparent');
              setDisplay(false)

                setTimeout(function(){
                    navToggle.current.classList.remove('active');
                    mobnav.current.classList.remove('display');
                }, 500);
                
            },80 * 10); 
      }
  
  }

  return (
    <StaticQuery
      query={graphql`
        query Header {
          mainMenu: wpMenu(slug: {eq: "main-menu"}) {
            menuItems {
              nodes {
                label
                url
                path
                target
              }
            }
          }
          tennisMenu: wpMenu(slug: {eq: "real-tennis-menu"}) {
            menuItems {
              nodes {
                label
                url
                path
              }
            }
          }
          racketsMenu: wpMenu(slug: {eq: "rackets-menu"}) {
            menuItems {
              nodes {
                label
                url
                path
              }
            }
          }
          squashMenu: wpMenu(slug: {eq: "squash"}) {
            menuItems {
              nodes {
                label
                url
                path
              }
            }
          }
        }
      `}
      render={data => (
        <>
        {/* {console.log(data.mainMenu, data.tennisMenu, data.racketsMenu, data.squashMenu)} */}
          <header ref={header} className="header clearfix" role="banner">
            <div className="block">
                <div className="centered">
                  <div className="logo">
                    <a ref={logo} id="logo" href="/" rel="home">
                        <img src={Logo} alt="Manchester Tennis &amp; Racquet Club logo" />
                        <span>MANCHESTER TENNIS &amp; RACQUET CLUB</span>
                    </a>
                  </div>
                </div>
            </div>
          </header>
          <nav className="nav" role="navigation">
           <MainMenu menu={data.mainMenu} handleNavToggle={() => {}} />
          </nav>
            
          <div ref={navToggle} className="navToggle" onClick={() => handleNavToggle()}>
            <div className="line top"></div>
            <div className="line middle"></div>
            <div className="line bottom"></div>
          </div>

          {
            path === '/real-tennis/' ? <TennisMenu menu={data.tennisMenu} /> : ''
          }
          {
            path === '/squash/' ? <SquashMenu menu={data.squashMenu} /> : ''
          }
          {
            path === '/rackets/' ? <RacketsMenu menu={data.racketsMenu} /> : ''
          }

          <nav ref={mobnav} className="mob-nav" role="navigation">
            <div className="block">
              <div className="centered">	
                <MainMenu handleNavToggle={handleNavToggle} menu={data.mainMenu} />
              </div>
            </div>
          </nav>
        </>
      )}
    />
  )
}
