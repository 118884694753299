import React from 'react'
import { Link } from 'gatsby'

export default function MainMenu({menu, handleNavToggle}) {
  return (
    <ul>
    {
      menu.menuItems.nodes.map((link, index) => {
        return  link.target === '_blank' 
        ? <li onClick={() => handleNavToggle()} key={`link-${index}`}><a href={link.path} target="_blank">{link.label}</a></li> 
        : <li onClick={() => handleNavToggle()} key={`link-${index}`}><Link to={link.path}>{link.label}</Link></li>
      })
    }
    </ul>
  )
}
