import React from 'react'
import {StaticQuery, graphql } from 'gatsby'
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';

export default function Footer({data}) {
  return (

    <StaticQuery
      query={graphql`
        query Footer {
          wp {
            myOptionsPage {
              options {
                address
                email
                email2
                phone
              }
            }
          }
        }
      `}
      render={data => (
        <footer className="footer">
          <div className="footerLeft">
            <div id="address">{data.wp.myOptionsPage.options.address}</div>
              <div id="phone"><span className="gold">T:</span>{data.wp.myOptionsPage.options.phone}</div>
              <div id="email"><span className="gold">E:</span> <a href={`mailto:${data.wp.myOptionsPage.options.email2}`}>{data.wp.myOptionsPage.options.email2}</a></div>
          </div>
            
          <div id="social">
            <a href="https://www.facebook.com/mcrsport" target="_blank"><FaFacebookSquare /></a>
            <a href="https://twitter.com/mtrc1874" target="_blank"><FaTwitterSquare /></a>
          </div>
          
          <div className="covid">
            <h6>
              COVID-19
            </h6>
            <p>
              To ensure the safety of our members, guests and staff we have undertaken COVID-19 risk assessments and continue to operate and to play to the latest guidelines laid out by the <a href="https://www.tennisandrackets.com/news/covid-19#Latest%20News" target="_blank">Tennis and Rackets Association</a> and <a href="https://www.gov.uk/guidance/working-safely-during-coronavirus-covid-19/providers-of-grassroots-sport-and-gym-leisure-facilities" target="_blank">HM Government</a>.
            </p>
          </div>
        </footer>
      )}
    />
  )
}
