import React from 'react'

export default function TennisMenu({menu}) {
  return (
    <div className="menu-real-tennis-menu-container">
      <ul id="menu-real-tennis-menu" className="menu">
        {
        menu.menuItems.nodes.map(link => {
          return <li><a href={link.path}>{link.label}</a></li>
        })
        }
      </ul>
    </div>
  )
}
